import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatDateParam } from 'src/app/_shared/helpers/dates';
import { ApiService } from '../../http/api.service';

export interface UserRegisterData {
  username: string;
  email: string;
  phone: string;
  password: string;
  cuit: string;
  recaptcha: string;
  realName: string;
  lastname: string;
  acopiosId: any[];
}
@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    public http: ApiService
  ) { }

  public login(username: string, password: string, recaptcha: string) {
    const body = { username, password, recaptcha };
    return this.http.post('usuarios/login', body);
  }

  /**
   * Deletes current session's refresh and authorization tokens in the backend.
   */
  public logout(fcmToken: string) {
    const body = { fcmToken };
    return this.http.post('usuarios/logout', body);
  }

  /**
   * Calls service in order to register user.
   * @param userFormData form data to register a new user
   */
  public registerUser(userFormData: UserRegisterData) {
    return this.http.post('usuarios', userFormData);
  }

  /**
   * Returns list of users.
   * @param page page
   * @param count count
   * @param orderBy order by
   * @param order order
   * @param filters filters
   * @param rolesfilters roles filters
   */
  public getUsers(
    page: number,
    count: number,
    orderBy: string,
    order: string,
    filters: any,
    rolesFilters: any = null
  ) {
    let params = new HttpParams()
      .set('pagina', page.toString())
      .set('cantidad', count.toString())
      .set('ordenadoPor', orderBy)
      .set('orden', order);
    // If roles filters are set, we add it to params as a list of array items.
    if (rolesFilters?.length > 0) {
      params = this.http.addArrayParams(rolesFilters, 'roles', params);
    }

    const filtros = { ...filters };

    let { miembroDesde } = filters;

    if (miembroDesde) {
      miembroDesde = formatDateParam(miembroDesde);

      filtros.miembroDesde = miembroDesde;
    }

    params = this.http.addFilterParams(filtros, params);

    return this.http.get('usuarios', params);
  }

  public refreshToken(refreshToken: string) {
    const body = { refreshToken };
    return this.http.post('usuarios/refresh-token', body);
  }

  public pingServer() {
    return this.http.get('payments/');
  }

  /**
   * Returns all the information for a specific user identified by userId.
   * @param userId String that identifies the user's info to be returned.
   */
  public getUser(userId: string) {
    const params = new HttpParams();
    return this.http.get(`usuarios/perfiles/${userId}`, params);
  }

  /**
   * Returns active user data.
   */
  public getUserData() {
    const params = new HttpParams();
    return this.http.get('usuarios/perfiles', params);
  }

  /**
   * Return roles.
   */
  public getRoles() {
    return this.http.get('usuarios/roles');
  }

  /**
   * Return States.
   */
  public getStates() {
    return this.http.get('usuarios/estados');
  }

  /**
   * Create user.
   * @param body request data
   */
  public addUser(body) {
    return this.http.post('usuarios/perfiles', body);
  }

  /**
   * Update user.
   * @param userId user id
   * @param body request data
   */
  public editUser(userId, body) {
    return this.http.put(`usuarios/perfiles/${userId}`, body);
  }

  /**
   * Update user's status.
   * @param userId user id.
   * @param body request data.
   */
  public editUserStatus(userId, body) {
    return this.http.patch(`usuarios/perfiles/${userId}`, body);
  }

  /**
   * Delete user.
   * @param userId user id.
   */
  public deleteUser(userId) {
    return this.http.delete(`usuarios/perfiles/${userId}`);
  }

  /**
   * Get all cuits of given user.
   * @param userId userId.
   */
  public getUserCuits(userId: number) {
    const params = new HttpParams()
      .set('userId', userId.toString());
    return this.http.get('cuit/datos-legales', params);
  }

  /**
   * Add cuit passed by parameter to current user
   * @param cuit cuit
   */
  public addCuit(cuit: string) {
    const body = { cuit };
    return this.http.post('cuit', body);
  }

  /**
   * Delete cuit from user.
   * @param cuit cuit.
   */
  public deleteCuitFromUser(cuit: string) {
    return this.http.delete(`cuit/${cuit}`);
  }

  /**
   * Add phone passed by parameter to current user
   * @param phone phone
   */
  public setUserPhone(phone: string) {
    const body = { phone };
    return this.http.patch('usuarios/telefono', body);
  }

  /**
   * Sends phone code for its validation.
   * @param userId userId
   * @param codigo code
   */
  public validatePhoneCode(userId: string, codigo: string) {
    const body = { codigo };
    return this.http.post(`usuarios/telefono/validar-codigo/${userId}`, body);
  }

  /**
   * ReSends phone code for its validation.
   * @param userId userId
   */
  public resendPhoneCode(userId: string) {
    return this.http.post(`usuarios/telefono/reenviar-codigo/${userId}`, {});
  }

  /**
   * Validates if cuit exists in DB and if is not already assigned to user.
   * @param cuit cuit
   * @param userId userId
   */
  public validateNewCuit(cuit: string, userId: number) {
    const params = new HttpParams()
      .set('cuit', cuit)
      .set('userId', userId.toString());

    return this.http.get('usuarios/validar-cuit', params);
  }

  /**
   * Said to BE that user whants to change password.
   * @param username username
   */
  public resetPassword(username: string, recaptcha: string) {
    const body = { username, recaptcha };
    return this.http.post('usuarios/resetear-contrasena', body);
  }

  /**
   * Validates code for password changes.
   * @param codigo code
   */
  public validateCode(codigo: string) {
    const params = new HttpParams();
    return this.http.get(`usuarios/resetear-contrasena/validar-codigo/${codigo}`, params);
  }

  /**
   * Validates code and email for user registration.
   * @param codigo code
   * @param email email
   */
  public validateRegistrationParameters(token: string, email: string) {
    const params = new HttpParams()
      .set('token', token)
      .set('mail', email);
    return this.http.get('usuarios/validar-email', params);
  }

  /**
   * Validates code and email for user confirmation.
   * @param codigo code
   * @param email email
   */
  public validateConfirmationParameters(token: string, email: string) {
    const params = new HttpParams()
      .set('token', token)
      .set('mail', email);
    return this.http.get('usuarios/activar-cuenta', params);
  }

  /**
   * Validates code for password changes.
   * @param codigo password
   */
  public savePassword(password: string, codigo: string) {
    const body = { password };
    return this.http.patch(`usuarios/cambiar-contrasena/${codigo}`, body);
  }

  /**
   * Sends the email change confirmation
   * @param token validation token
   * @param email email to be confirmed
   * @returns observable with the response of the request
   */
  sendMailChangeConfirmation(token: string, email: string) {
    const params = new HttpParams()
      .set('token', token)
      .set('mail', email);
    return this.http.get('usuarios/validar-email-editado', params);
  }
}
