import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmParams {
  title: string;
  description: string;
  cancelLabel?: string;
  confirmLabel?: string;
}

@Component({
  selector: 'agd-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {
  title: string;

  description: string;

  cancelLabel: string;

  confirmLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: ConfirmParams) {
    this.cancelLabel = data?.cancelLabel || 'Cancelar';
    this.confirmLabel = data?.confirmLabel || 'Confirmar';

    this.title = data?.title;
    this.description = data?.description;
  }
}
