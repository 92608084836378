import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ConfirmDialogComponent, ConfirmParams } from 'src/app/_shared/modules/agd-components/confirm-dialog/confirm-dialog.component';

@Injectable()
export class ConfirmDialogService {
  private readonly dialog = inject(MatDialog);

  show(config: ConfirmParams) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: 'auto',
      height: 'auto',
      maxWidth: 380,
      maxHeight: 220,
      disableClose: true,
      autoFocus: false,
      data: config,
      panelClass: ['confirm-dialog'],
    });

    return firstValueFrom(dialogRef.afterClosed());
  }
}
