import { TyCDialogData, TerminosYCondicionesService } from '../../services/terminos-y-condiciones.service';
import {
  Component, ElementRef, Inject, inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { GoogeTagService } from 'src/app/_core/services/google-tag-manager/googe-tag-manager.service';
import { AppAnimations } from 'src/app/_shared/animations/animations';
import { SubSink } from 'subsink';

export interface TyC {
  id: number;
  tipo: string;
  titulo: string;
  contenido: string;
  tituloRechazo?: string;
  contenidoRechazo?: string;
  alias?: string;
  leido?: boolean;
  estado?: boolean;
  version: number;
  createdAt: string;
}

@Component({
  selector: 'agd-tyc-modal',
  templateUrl: './tyc-modal.component.html',
  styleUrl: './tyc-modal.component.scss',
  animations: [AppAnimations.fadeIn],
})
export class TycModalComponent implements OnInit, OnDestroy {
  @ViewChildren('stepRef') stepRef: QueryList<ElementRef>;

  @ViewChild('textContainer') textContainer: ElementRef;

  loading: boolean;

  showCloseButton: boolean;

  showAcceptCheckBox: boolean;

  showRejectButton: boolean;

  content: TyC[];

  active: number;

  private scrolledOnce: boolean;

  private readonly tycService = inject(TerminosYCondicionesService);

  private readonly userData = inject(UserDataService);

  private readonly subs = new SubSink();

  private readonly googleTagService = inject(GoogeTagService);

  constructor(
    private readonly dialogRef: MatDialogRef<TycModalComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: TyCDialogData
  ) { }

  get activeStep() {
    return this.content[this.active];
  }

  get scrolled() {
    if (this.scrolledOnce) return true;

    const element = this.textContainer?.nativeElement;

    if (!element) {
      return false;
    }

    // si el contenedor del texto se scrolleo hasta el 99% del contenido se devuelve true
    const scrollSized = Math.round(element.scrollHeight * 0.99);
    const scrollPos = Math.round(element.scrollTop + element.clientHeight);

    this.scrolledOnce = scrollPos >= scrollSized;

    return this.scrolledOnce;
  }

  ngOnInit(): void {
    const {
      tyc, showCloseButton, showAcceptCheckBox = true, showRejectButton = true
    } = this.data;
    this.content = tyc;
    this.showCloseButton = showCloseButton;
    this.showAcceptCheckBox = showAcceptCheckBox;
    this.showRejectButton = showRejectButton;

    this.active = 0;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  accept() {
    if (this.data?.onAccept) {
      this.data.onAccept(this.content[this.active].id);
      this.close();
      return;
    }
    this.resolve(true);
  }

  reject() {
    if (this.data?.onReject) {
      this.data.onReject(this.content[this.active].id);
      this.close();
      return;
    }
    const { tituloRechazo, contenidoRechazo } = this.activeStep;

    const resolveReject = (rejected: boolean) => {
      if (rejected) {
        this.resolve(false);
      }
    };

    if (tituloRechazo && contenidoRechazo) {
      this.tycService.confirmReject(tituloRechazo, contenidoRechazo).then(resolveReject);
    } else {
      resolveReject(true);
    }
  }

  toggleCheck(event: MatCheckboxChange) {
    this.activeStep.leido = event.checked;
  }

  close() {
    this.dialogRef.close();
  }

  private resolve(estado: boolean) {
    this.loading = true;

    const cuit = this.userData.activeCUIT;

    const { id: tycId } = this.activeStep;

    this.subs.sink = this.tycService.sendResponse({
      tycId,
      estado,
      cuit,
    }).subscribe({
      next: () => {
        this.activeStep.estado = estado;

        // Si es el modal de TyC de venta de granos, registramos el resultado en GTM
        if (this.activeStep.tipo === 'INICIO_GRANOS') {
          this.googleTagService.sendPermisoVentaTyC(estado ? 'Aceptar' : 'Rechazar');
        }

        if (this.active === this.content.length - 1) {
          this.dialogRef.close();
        } else {
          this.active += 1;
          this.focusActive();
          this.loading = false;
        }
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  private focusActive() {
    // usamos el timeout para esperar que se muestre el icono y se actualice el tamaño del contenedor
    setTimeout(() => {
      const step = this.stepRef.get(this.active);
      step?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }, 500);
  }
}
