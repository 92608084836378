import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ApiService } from 'src/app/_core/http/api.service';
import { ConfirmDialogService } from 'src/app/_shared/modules/agd-components/confirm-dialog/confirm-dialog.service';
import { TyC, TycModalComponent } from '../components/tyc-modal/tyc-modal.component';
import { HttpParams } from '@angular/common/http';

export interface TyCDialogData {
  tyc: TyC[];
  showAcceptCheckBox?: boolean;
  showCloseButton?: boolean;
  showRejectButton?: boolean;
  onAccept?: (tycId: number) => void;
  onReject?: (tycId: number) => void;
}

interface TyCAnswer {
  tycId: number;
  cuit: string;
  estado: boolean;
}

@Injectable()
export class TerminosYCondicionesService {
  private readonly dialog = inject(MatDialog);

  private readonly confirmService = inject(ConfirmDialogService);

  constructor(
    private readonly http: ApiService,
  ) { }

  showTOSDialog(params: TyCDialogData): Promise<any> {
    // si hay mas de 1 tyc, mantenemos el mismo alto para que no cambie al cargar y cambiar el contenido
    const height = params.tyc?.length > 1 ? '100%' : 'auto';

    const dialogRef = this.dialog.open(TycModalComponent, {
      width: '600px',
      height,
      maxWidth: 600,
      disableClose: !params.showCloseButton,
      data: params,
      panelClass: ['no-min-width', 'venta-de-granos-dialog', 'TOS-dialog'],
      autoFocus: false,
    });

    return firstValueFrom(dialogRef.afterClosed());
  }

  confirmReject(title: string, description: string) {
    return this.confirmService.show({
      title,
      description,
      cancelLabel: 'Volver atrás',
      confirmLabel: 'Si, rechazar'
    });
  }

  sendResponse(tycData: TyCAnswer) {
    return this.http.post('tyc', tycData);
  }

  getTyCByTypes(cuit, types: string[]) {
    const params = new HttpParams()
      .set('cuit', cuit)
      .append('tipos', types.join(', '));
    return this.http.get('tyc', params);
  }
}
