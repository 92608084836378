<span class="title"> Términos y condiciones </span>
@if (showCloseButton) {
    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="close" >
        <mat-icon>close</mat-icon>
    </button>
}
@if (content?.length) {
    <div class="stepper" @fadeIn>
        @for (tyc of content; track $index) {
            <div class="step-container">
                <div
                    class="step"
                    [ngClass]="{ aceptado: tyc.estado, rechazado: tyc.estado === false }"
                    [class.activo]="active === $index"
                    #stepRef
                >
                    {{ tyc.alias }}
                    @if (tyc.estado) {
                        <mat-icon svgIcon="done" @fadeIn></mat-icon>
                    }

                    @if (tyc.estado === false) {
                        <mat-icon svgIcon="rejected" @fadeIn></mat-icon>
                    }
                </div>
            </div>
        }
    </div>
}
<agd-info-box @fadeIn> Lee atentamente términos y condiciones antes de aceptar o rechazar. </agd-info-box>
@if (!loading) {
    <div class="text-container">
        <section @fadeIn #textContainer>
            @if (activeStep.titulo) {
                <div class="tos-title" [innerHTML]="activeStep.titulo"></div>
            }
            <div [innerHTML]="activeStep.contenido"></div>
        </section>
      @if(showAcceptCheckBox) {
        <mat-checkbox
            @fadeIn
            [checked]="activeStep.leido"
            [disabled]="!scrolled"
            (change)="toggleCheck($event)"
            color="primary"
        >
            He leido términos y condiciones
        </mat-checkbox>
      }
    </div>
    <div class="actions" @fadeIn>
      @if(showRejectButton) {
        <button mat-flat-button (click)="reject()">Rechazar</button>
      }
        <button mat-flat-button class="primary" [disabled]="!activeStep.leido && showAcceptCheckBox" (click)="accept()">Aceptar</button>
    </div>
}

@if (loading) {
    <mat-spinner class="spinner" mode="indeterminate" diameter="50" @fadeIn></mat-spinner>
}
